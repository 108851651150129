$c-primary: #00a7e1;
$c-secandary: #00a7e1;
$dots: false;
$version: 3.1;

$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;

@font-face {
  font-family: 'Effra';
  src: url('#{$project-font-path}Effra-Bold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Effra';
  src: url('#{$project-font-path}Effra-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Effra';
  src: url('#{$project-font-path}Effra-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

$base-font-family: 'Effra', sans-serif;
$font-family-heading: 'Effra', sans-serif;
