
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/resimac/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  background-color: var(--page-background-color);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 34;

  & > div {
    padding: 2rem;
  }
  .heading {
    font-family: var(--heading-font-family) !important;
    color: var(--heading-text-color) !important;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: left;
    font-weight: 600;
    font-size: var(--heading-font-size) !important;
    line-height: 31px;
    letter-spacing: -0.5px;
  }

  .title {
    font-size: 1rem;
  }

  .spinner1 {
    margin-bottom: 40px;
    margin-left: 23px;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    box-shadow: 24px 24px var(--loading-spinner-dot1), -24px 24px var(--loading-spinner-dot2),
      -24px -24px var(--loading-spinner-dot3), 24px -24px var(--loading-spinner-dot4);
    -webkit-animation: spin ease infinite 2s;
  }
  @-webkit-keyframes spin {
    0%,
    100% {
      box-shadow: 24px 24px var(--loading-spinner-dot1), -24px 24px var(--loading-spinner-dot2),
        -24px -24px var(--loading-spinner-dot3), 24px -24px var(--loading-spinner-dot4);
    }
    25% {
      box-shadow: -24px 24px var(--loading-spinner-dot4), -24px -24px var(--loading-spinner-dot1),
        24px -24px var(--loading-spinner-dot2), 24px 24px var(--loading-spinner-dot3);
    }
    50% {
      box-shadow: -24px -24px var(--loading-spinner-dot3), 24px -24px var(--loading-spinner-dot4),
        24px 24px var(--loading-spinner-dot1), -24px 24px var(--loading-spinner-dot2);
    }
    75% {
      box-shadow: 24px -24px var(--loading-spinner-dot2), 24px 24px var(--loading-spinner-dot3),
        -24px 24px var(--loading-spinner-dot4), -24px -24px var(--loading-spinner-dot1);
    }
  }
}
