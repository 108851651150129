
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/resimac/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
@import '../IdSelection/IdSelection.style.module.scss';
$fill: var(--primary);
@if ($version >= 3) {
  $fill: #000;
}

.wrapper {
  .title {
    margin: 0 auto 0;
    width: 75%;
  }

  .blocks {
    margin-top: 45px;
    height: calc(100% - 240px);
    overflow-y: auto;
    .item {
      margin-bottom: 15px;
      height: 90px;

      .icon {
        svg {
          margin-top: 4%;
          width: 90%;
          height: 90%;
          display: block;
          fill: $fill;
        }
      }

      .content {
        padding-left: 15px;
        text-align: left;
        display: flex;
        align-self: center;
        flex-direction: column;

        h4 {
          font-size: 17px;
          font-weight: 500;
          margin: 0;
          margin-bottom: 5px;
        }
      }
    }
  }
}
