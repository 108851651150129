
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/resimac/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: calc(100% - 165px);

  .heading {
    font-family: var(--heading-font-family) !important;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 16px;
    margin-top: 66px;
  }

  .title {
    width: 80%;
    margin: 0 auto;
    font-family: var(--base-font-family) !important;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.3px;
  }

  .img-container {
    width: 100%;
    max-width: 142px;
    text-align: center;
    img {
      width: 100%;
    }
  }

  .icon {
    width: 100%;

    svg {
      max-height: 380px;
    }
  }

  .button {
    background: none !important;
    border-color: #fff !important;
    font-family: var(--base-font-family) !important;
    width: 200px;
    opacity: 0;
    height: 41px !important;
    transition: opacity 0.7s ease-in;

    &.open {
      opacity: 1;
    }
  }
}

@media (max-width: 750px) {
  .wrapper .icon,
  .wrapper .icon svg {
    max-height: 230px;
  }
}

@media (max-height: 321px) {
  .wrapper .icon,
  .wrapper .icon svg {
    max-height: 180px;
  }
}

@media (max-height: 265px) {
  .wrapper .icon {
    max-height: 180px;

    svg {
      margin-top: -15px;
    }
  }
}
