
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/resimac/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding: 25px;
  text-align: center;
  max-width: 545px;
  margin: auto;

  .countryTitle {
    font-size: 23px;
    margin-bottom: 45px;
  }

  .blocks {
    margin-top: 55px;
    min-height: 260px;

    .item {
      display: flex;
      padding: 10px 5px;
      height: 105px;

      .icon {
        width: 110px;
        background-position: center;
        background-size: 90%;
        background-repeat: no-repeat;
      }

      .iconSmall {
        background-size: 50% !important;
      }

      .content {
        padding-left: 15px;
        text-align: left;
        display: flex;
        align-self: center;
        flex-direction: column;

        h4 {
          font-size: 17px;
          font-weight: 500;
          margin: 0;
          margin-bottom: 5px;
        }

        span {
          font-size: 13px;
        }
      }
    }
  }
}

@media (max-height: 510px) {
  .wrapper {
    display: block;
    position: relative;
    padding: 0;
    .blocks {
      margin-top: 25px;
    }
  }
}
