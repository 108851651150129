
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/resimac/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
$loader-dot1: var(--primary);
$loader-dot2: var(--secondary);
$loader-dot3: var(--primary);
$loader-dot4: var(--secondary);

$even: var(--secondary);
@if var(--primary) == var(--secondary) {
  $even: lighten($c-secandary, 40%);
  $loader-dot2: lighten($c-secandary, 40%);
  $loader-dot4: lighten($c-secandary, 40%);
}

@if variable-exists(short-loader-dot1) {
  $loader-dot1: $short-loader-dot1;
}
@if variable-exists(short-loader-dot2) {
  $loader-dot2: $short-loader-dot2;
}
@if variable-exists(short-loader-dot3) {
  $loader-dot3: $short-loader-dot3;
}
@if variable-exists(short-loader-dot4) {
  $loader-dot4: $short-loader-dot4;
}

.wrapperSp {
  .spinner1 {
    margin-bottom: 40px;
    margin-left: 23px;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    box-shadow: 24px 24px $loader-dot1, -24px 24px $loader-dot2, -24px -24px $loader-dot3,
      24px -24px $loader-dot4;
    -webkit-animation: spin ease infinite 2s;
  }
  @-webkit-keyframes spin {
    0%,
    100% {
      box-shadow: 24px 24px $loader-dot1, -24px 24px $loader-dot2, -24px -24px $loader-dot3,
        24px -24px $loader-dot4;
    }
    25% {
      box-shadow: -24px 24px $loader-dot4, -24px -24px $loader-dot1, 24px -24px $loader-dot2,
        24px 24px $loader-dot3;
    }
    50% {
      box-shadow: -24px -24px $loader-dot3, 24px -24px $loader-dot4, 24px 24px $loader-dot1,
        -24px 24px $loader-dot2;
    }
    75% {
      box-shadow: 24px -24px $loader-dot2, 24px 24px $loader-dot3, -24px 24px $loader-dot4,
        -24px -24px $loader-dot1;
    }
  }
}
