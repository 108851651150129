
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/resimac/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  .title {
    color: var(--loading-text-color);
    margin-top: -33px;
    margin-bottom: 20px;
  }

  .progress {
    height: 10px;
    background: var(--loading-progress-bar-incomplete);
    width: 80%;
    border-radius: 3px;
    margin: 0 auto;
    position: relative;
    max-width: 300px;

    .bar {
      border-radius: 3px;
      background-color: var(--loading-progress-bar);
      position: absolute;
      left: 0;
      top: 0;
      width: 0;
      height: 100%;
      transition: width 0.6s ease;
    }
  }

  .svgDot {
    path {
      fill: var(--loading-dot);
    }
  }

  .top {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
  }

  .bottom {
    position: absolute;
    width: 100%;
    height: auto;
    bottom: 0;
    left: 0;
  }
}
