
            @import 'src/styles/settings/_fontpath.scss';
            @import 'config/projects/resimac/style.scss';
            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  font-weight: 300;

  .content {
    margin-top: 25px;

    h3 {
      font-size: 19px;
      font-weight: 300;
    }
  }

  img {
    margin-top: 25px;
    width: 100%;
    max-width: 190px;
    border-radius: 10px;
  }
}

@media (max-height: 625px) {
  .wrapper {
    .content {
      margin-top: 10px;
    }

    img {
      max-width: 150px;
    }
  }
}

@media (max-height: 545px) {
  .wrapper {
    .content {
      margin-top: -20px;
    }

    img {
      max-width: 140px;
    }
  }
}
